@import '../../variables';

@each $name, $color in $colors {
  .#{$name} {
    background-color: $color; } }

.section {
  padding: 4rem 0; }

.black {
  background-color: black; }

.container {
  width: 90%;
  max-width: 1460px;
  margin: 0 auto; }

@media (min-width: 992px) {
  .section {
    padding: 7rem 0; } }
