.link {
  display: block; }

.withIcon {
  display: flex;
  align-items: center;
  gap: .75rem;

  .label {
    display: none; } }

.label {
  text-transform: capitalize; }

@media screen and ( min-width: 768px ) {
  .withIcon {
    .label {
      display: block; } } }
