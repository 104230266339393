@media (max-width: 992px) {
  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }

  .submenu {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: black;
    height: 100vh;
    width: 100vw; }

  .isOpen {
    display: flex; } }

@media (min-width: 992px) {
  .menu {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;

    li {
      &:hover {
        .submenu {
          display: flex; } } } }

  .submenu {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: rgba(0, 0, 0, .5);
    flex-direction: column;
    padding: 1rem 2rem;

    li {
      margin-left: 0;
      padding: .5rem 0;

      a {
        line-height: 1.5; } } } }
