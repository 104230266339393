@import '../../variables';

.footer {
  padding: 3rem;
  background-color: map-get($colors, black); }

.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  grid-gap: 1.25rem; }

.link {
  @include text;
  color: map-get($colors, white); }

@media (min-width: 768px) {
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }

  .nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-column-gap: 1.5rem; } }
