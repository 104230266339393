.header {
  width: 100%;
  display: flex;
  position: fixed;
  z-index: 10;
  border-bottom: 1px solid rgba(256, 256, 256, .3); }

.onPageTop {
  background-color: black; }

.container {
  width: 90%;
  display: flex;
  margin: 0 auto;
  padding: .5rem 0;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; }

.logo {
  width: 6rem;
  height: 1.75rem;
  z-index: 2; }

.burger {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  z-index: 2;

  path {
    fill: white; } }

@media screen and ( max-width: 992px ) {
  .nav {
    width: 100%;

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      height: auto;

      a, span {
        font-size: .75rem; } } }

  .listItem {
    display: flex;
    align-items: center;
    flex-direction: column; }

  .submenu {
    margin-top: .5rem !important;
    margin-bottom: .5rem;
    a {
      font-size: 1.25rem !important;
      color: white; } }

  .navClosed {
    @extend .nav;
    height: 0px;
    overflow: hidden;
    display: none; } }

@media screen and ( min-width: 992px ) {
  .header {
    height: 60px; }

  .container {
    padding: 0; }

  .burger {
    display: none; }

  .nav {
    display: flex;
    align-items: center;

    ul {
      display: flex;
      flex-direction: row;
      column-gap: 2rem; } }

  .listItem {
    a, span {
      font-size: 1rem;
      cursor: pointer;
      color: white; }

    &:hover {
      .submenu {
        display: flex !important;
        flex-direction: column;
        padding: 2rem 1.5rem;
        background-color: black;
        position: absolute;
        a {
          font-size: 1rem;
          margin: .25rem 0; } } } }

  .submenu {
    display: none !important; } }
