@import './_variables';

p, a, h1, h2, h3, h4, h5, h6, li, span, button, div {
  font-family: $primary-font; }

a {
  text-decoration: none; }

.text {
  font-size: 1rem;
  line-height: 1.5;

  &--small {
    font-size: .825rem; } }

.offerSection, .two-column {
  display: grid;
  grid-row-gap: 3rem; }

.newsletter {
  display: grid;
  grid-row-gap: 2rem;
  grid-auto-rows: auto;
  grid-column-gap: 4rem; }

.section {
  padding: 4rem 0; }

.container {
  width: 90%;
  max-width: 1460px;
  margin: 0 auto; }

.noBottom {
  padding-bottom: 0px !important; }

@media (min-width: 992px) {
  .section {
    padding: 7rem 0; } }

@media (min-width: 1280px) {
  .two-column {
    grid-template-columns: .8fr 1fr; } }
