$primary-font: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

$colors: (black: #000, white: #fff, grey: #AFAFAF);

@mixin text {
  font-size: 1rem;
  line-height: 1.5; }

@mixin smallText {
  font-size: .825rem;
  line-height: 1.5; }

// .bg--#{$name}
//   background-color: $color

@each $name, $color in $colors {
  .font--#{$name} {
    color: $color; } }
