@media screen and ( max-width: 992px ) {

  .nav {
    display: none;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: black;
    height: 100vh;
    width: 100vw; }

  .isOpen {
    display: flex; } }

@media screen and ( min-width: 992px ) {
  .nav {
    display: block;
    height: 100%; } }
