@import '../../variables';

.text {
  @include text; }

.smallText {
  @include smallText; }

@each $name, $color in $colors {
  .#{$name} {
    color: $color; } }
