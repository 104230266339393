@import '../../../../variables';

.listItem {
  margin-left: 2.5rem;
  text-transform: uppercase; }

.link {
  color: map-get($colors, white);
  font-size: .75rem;
  font-weight: 500;
  text-decoration: none;
  height: 100%;
  letter-spacing: 2px;
  line-height: 60px; }
